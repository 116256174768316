import { Alignment, Button, Divider, Menu, MenuItem, Navbar, Position } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { observer } from 'mobx-react-lite';
import { downloadFile } from 'polotno/utils/download';
import { formatStudioJson } from '../../Services/Json/formatStudio';
import { getCustomSvgData } from '../../Utils';
import { OpenFile } from './Components';
import { DownloadButton } from './Components/DownloadButton';
import { NavbarContainer, NavInner } from './styles';

export const TopBar = observer(({ store }: { store: any }) => {
  return (
    <NavbarContainer className="bp4-navbar">
      <NavInner>
        <Navbar.Group align={Alignment.LEFT}>
          <Button
            icon="new-object"
            minimal
            onClick={() => {
              const ids = store.pages.map((page: any) => page.children.map((child: any) => child.id)).flat();
              const hasObjects = ids?.length;
              if (hasObjects) {
                if (!window.confirm('Remove all content for a new design?')) {
                  return;
                }
              }
              const pagesIds = store.pages.map((p: any) => p.id);
              store.deletePages(pagesIds);
              store.addPage();
              // setPublishedTemplate(false);
            }}>
            Novo
          </Button>

          <>
            <OpenFile store={store} />

            <Popover2
              content={
                <Menu>
                  <MenuItem
                    text="Builder"
                    onClick={() => {
                      const json = store.toJSON();
                      const url = 'data:text/json;base64,' + window.btoa(unescape(encodeURIComponent(JSON.stringify(json))));
                      downloadFile(url, 'builder.json');
                    }}
                  />
                  <MenuItem
                    text="Studio"
                    onClick={async () => {
                      const customSvgData = await getCustomSvgData(store);

                      const json = await formatStudioJson(store.toJSON(), {
                        customSvgData,
                      });

                      const url = 'data:text/json;base64,' + window.btoa(unescape(encodeURIComponent(JSON.stringify(json))));

                      downloadFile(url, 'studio.json');
                    }}
                  />
                </Menu>
              }
              position={Position.BOTTOM}>
              <Button icon="download" minimal>
                Baixar JSON
              </Button>
            </Popover2>
          </>
          {/* <CreateTemplateButton store={store} /> */}

          {/* {publishedTemplate && (
            <>
              <DeleteTemplateButton store={store} />
            </>
          )}

          {publishedTemplate && (
            <>
              <Divider />
              <Tag intent="warning">
                Editando template:&nbsp;<strong>{publishedTemplate.title}</strong>
              </Tag>
            </>
          )} */}
        </Navbar.Group>

        <Navbar.Group align={Alignment.RIGHT}>
          <Divider />

          <Divider />
          <DownloadButton store={store} />
        </Navbar.Group>
      </NavInner>
    </NavbarContainer>
  );
});
