// import our own icon
import { Button } from '@blueprintjs/core';
import { StoreType } from 'polotno/model/store';

export const CustomTexts = ({ store }: { store: StoreType }) => {
  const texts = [
    {
      name: 'Text Plain',
      action: () => {
        store.activePage?.addElement({
          type: 'text',
          x: 50,
          y: 50,
          fill: 'black',
          text: 'Text Plain',
          custom: {
            type: 'DesignElementTextPlain',
          },
        });
      },
    },
    {
      name: 'Text Styled',
      action: () => {
        store.activePage?.addElement({
          type: 'text',
          x: 50,
          y: 50,
          fill: 'black',
          text: 'Text Styled',
          custom: {
            type: 'DesignElementTextStyled',
          },
        });
      },
    },
  ];

  return (
    <>
      {texts.map((v, k) => (
        <Button
          minimal
          onClick={v.action}
          key={k}
          style={{
            marginBottom: '5px',
            width: '100%',
            fontSize: '25px',
            fontFamily: 'Roboto',
          }}>
          {v.name}
        </Button>
      ))}
    </>
  );
};
