import { FormGroup, Intent, MenuItem } from '@blueprintjs/core';
import { MultiSelect2 } from '@blueprintjs/select';
import '@blueprintjs/select/examples';
import { StoreType } from 'polotno/model/store';
import { GLOBAL_DEFAULT_FONTS } from 'src/Services/Json/data';

interface Props {
  fonts: any[];
  setFonts: React.Dispatch<React.SetStateAction<any[]>>;
  store: StoreType;
}
export const CustomFonts = ({ fonts, setFonts }: Props) => {
  return (
    <FormGroup label="Selecionar fontes disponíveis">
      <MultiSelect2<string>
        items={GLOBAL_DEFAULT_FONTS}
        selectedItems={fonts}
        itemRenderer={(item, props) => {
          const isSelected = !!fonts.find((i) => i === item);
          return (
            <MenuItem
              style={{
                fontFamily: item,
              }}
              onClick={() => {
                if (!isSelected) {
                  setFonts((prev) => [...prev, item]);
                } else {
                  setFonts((prev) => prev.filter((i) => i !== item));
                }
              }}
              selected={isSelected}
              shouldDismissPopover={false}
              text={item}
            />
          );
        }}
        noResults={<MenuItem disabled={true} text="Nenhuma fonte encontrada. Adicione uma na seção de textos." />}
        onItemSelect={() => {}}
        tagRenderer={(item) => item}
        tagInputProps={{
          onRemove: (_, index) => {
            setFonts((prev) => prev.filter((_, i) => i !== index));
          },
          tagProps: (_, index) => ({
            style: {
              fontFamily: GLOBAL_DEFAULT_FONTS[index],
            },
            intent: Intent.PRIMARY,
            minimal: false,
          }),
        }}
      />
    </FormGroup>
  );
};
