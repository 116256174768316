import { StoreType } from 'polotno/model/store';
import { v4 } from 'uuid';
import { GLOBAL_DEFAULT_COLORS, GLOBAL_DEFAULT_FONTS } from './data';
import { StyledTextCustomData } from './interface';
import { getFontUrl, mapGoogleFontNameToFontFace, mapTextStyledColors } from './mappers';
import { FontFace, StudioCustomData } from './studioInterface';

interface CustomData {
  // defaultFonts: TemplateFontFragment[];
  // defaultColors: TemplateColorFragment[];
  customSvgData: { [key: string]: string };
}

export const formatStudioJson = async (json: StoreType, { customSvgData }: CustomData) => {
  const newData: StudioCustomData = {
    data: {
      design: {
        __typename: 'Design',
        id: generateId(),
        createdAt: new Date().toString(),
        updatedAt: new Date().toString(),
        store: 'BR',
        productKey: 'unknown',
        version: 1,
        state: 'unknown',
        layout: 'CARD_PORTRAIT',
        scenes: [],
      },
    },
  };
  // Pages
  await Promise.all(
    json.pages.map(async (v, k) => {
      newData.data.design.scenes[k] = {
        id: generateId(),
        width: v.width === 'auto' ? json.width : v.width,
        height: v.height === 'auto' ? json.height : v.height,
        title: '',
        template: {
          __typename: 'DesignTemplate',
          id: generateId(),
          elements: [],
        },
      };
      // Page elements
      await Promise.all(
        v.children.map(async (value, key) => {
          const elementTypeName = value.type === 'svg' ? 'DesignElementImageStatic' : value.custom.type;

          let { src } = value;

          let maxChars = 0;

          if (elementTypeName === 'DesignElementTextStyled' || elementTypeName === 'DesignElementTextPlain')
            maxChars = value.custom?.maxChars ?? 0;

          const isTextStyled = elementTypeName === 'DesignElementTextStyled';
          const isImageUpload = elementTypeName === 'DesignElementImageUpload';
          const isAnyText = elementTypeName === 'DesignElementTextStyled' || elementTypeName === 'DesignElementTextPlain';

          if (value.type === 'svg' && Object.keys(value.colorsReplace).length > 0) {
            src = customSvgData[value.id];
          }

          newData.data.design.scenes[k].template.elements[key] = {
            __typename: elementTypeName,
            id: `${generateId()}:${key + 1}`,
            width: value.width,
            height: value.height,
            ...(isTextStyled
              ? {
                  availableColors:
                    ((value.custom as StyledTextCustomData)?.colors?.length ?? 0) > 0
                      ? (value.custom as StyledTextCustomData)?.colors?.map(mapTextStyledColors)
                      : // Previously used defaultColors
                      [].length > 0
                      ? [].map((mapValue: any) => {
                          return mapTextStyledColors(mapValue.hex);
                        })
                      : GLOBAL_DEFAULT_COLORS.map(mapTextStyledColors),
                  availableFontFaces:
                    ((value.custom as StyledTextCustomData)?.fonts?.length ?? 0) > 0
                      ? (value.custom as StyledTextCustomData)?.fonts?.map((mapValue) => {
                          const data: FontFace = {
                            __typename: 'DesignFontFace',
                            id: generateId(),
                            name: mapValue.name,
                            urls: {
                              ...getFontUrl(mapValue.name),
                              __typename: 'DesignFontFaceUrls',
                            },
                          };

                          return data;
                        })
                      : // Previously used defaultFonts
                      [].length > 0
                      ? [].map((mapValue: any) => {
                          const data: FontFace = {
                            __typename: 'DesignFontFace',
                            id: generateId(),
                            name: mapValue.name,
                            urls: {
                              ...getFontUrl(mapValue.name),
                              __typename: 'DesignFontFaceUrls',
                            },
                          };

                          return data;
                        })
                      : GLOBAL_DEFAULT_FONTS.map(mapGoogleFontNameToFontFace),
                  availableFontSizes: generateFontSizes(convertFontSize(value.fontSize)),
                }
              : {}),
            ...(isAnyText
              ? {
                  color: {
                    __typename: 'DesignColor',
                    id: generateId(),
                    name: `Color-${value.fill}`,
                    value: value.fill,
                  },
                  editableText: {
                    __typename: 'DesignTextEditable',
                    allowBlankText: false /*unknown*/,
                    allowDefaultText: false /*unknown*/,
                    customNum: 0 /*unknown*/,
                    defaultText: value.text,
                    maxCharacters: (maxChars < 0 ? 0 : maxChars) ?? 0,
                    textTransform: 'none' /*undefined*/,
                    lineHeight: value.lineHeight,
                    letterSpacing: value.letterSpacing + 'em',
                    fontWeight: value.fontWeight,
                    textDecoration: value.textDecoration,
                    fontStyle: value.fontStyle,
                  },
                  horizontalAlignment: value.align,
                  verticalAlignment: (() => {
                    switch (value.verticalAlign) {
                      case 'middle':
                        return 'center';
                      case 'bottom':
                        return 'flex-end';
                      case 'top':
                        return 'flex-start';
                    }
                  })(),
                  fontFace: {
                    __typename: 'DesignFontFace',
                    id: generateId(),
                    name: value.fontFamily,
                    urls: {
                      ...getFontUrl(value.fontFamily),
                    },
                  },
                  fontSize: convertFontSize(value.fontSize),
                }
              : {
                  ...(value.maskSrc
                    ? {
                        url: value.maskSrc,
                        maskUrl: src,
                      }
                    : {
                        url: src,
                      }),
                }),
            ...(isImageUpload
              ? {
                  required: value.custom.required,
                }
              : {}),
            num: key + 1,
            opacity: value.opacity,
            rotation: value.rotation,
            x: value.x,
            y: value.y,
          };
        }),
      );
    }),
  );

  return newData;
};

export function generateId() {
  const uuid = v4();

  const [first, second, third, fourth] = uuid.split('-');

  return first + second + third + fourth;
}

function generateFontSizes(fontSize: number): number[] {
  const arrayLen = 11;
  const gap = 5;

  const array = new Array(arrayLen).fill(0);

  array[5] = fontSize;

  for (let i = 0; i < arrayLen; i++) {
    if (i < 5) {
      array[i] = fontSize - (5 - i) * gap;
    } else if (i > 5) {
      array[i] = fontSize + (i - 5) * gap;
    }
  }

  return array;
}

function convertFontSize(fontSize: number) {
  return fontSize;
}
