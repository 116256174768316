import { FormGroup, InputGroup } from '@blueprintjs/core';
import { Dispatch, SetStateAction } from 'react';

interface Props {
  maxChars: number;
  setMaxChars: Dispatch<SetStateAction<number>>;
}

export const SelectMaxCars = ({ maxChars, setMaxChars }: Props) => {
  return (
    <FormGroup label="Limitar máximo de caracteres" labelFor="text-input" labelInfo="(0 para desativar)">
      <InputGroup
        id="text-input"
        placeholder="Placeholder text"
        type="number"
        value={maxChars.toString()}
        onChange={({ target }) => setMaxChars(Number(target.value))}
      />
    </FormGroup>
  );
};
