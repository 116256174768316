import { Button, Dialog, Intent } from '@blueprintjs/core';
import { useDisclosure } from '@chakra-ui/react';
import { ElementType } from 'polotno/model/store';
import { useEffect, useState } from 'react';
import { DialogBody, DialogFooter } from 'src/Components/Dialog';
import { AppToaster } from 'src/Components/Toast';
import { PlainTextCustomData } from 'src/Services/Json/interface';
import { SelectMaxCars } from '../SelectMaxChars';

export const SelectTextPlainType = ({ element }: { element: ElementType }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [maxChars, setMaxChars] = useState(0);

  const renderData = () => {
    const clone = Object.assign({}, element);

    element.set({
      custom: {
        ...clone.custom,

        maxChars,
      } as PlainTextCustomData,
    });

    onClose();

    AppToaster.show({
      intent: Intent.SUCCESS,
      message: 'Alteração salva.',
    });
  };

  useEffect(() => {
    const data: PlainTextCustomData = element.custom;

    setMaxChars(data?.maxChars ?? 0);
  }, [element.id]);

  return (
    <>
      <Dialog title="Personalizar Text Plain" isOpen={isOpen} onClose={onClose}>
        <DialogBody>
          <SelectMaxCars
            {...{
              maxChars,
              setMaxChars,
            }}
          />
        </DialogBody>
        <DialogFooter label="Salvar" handleClose={renderData} />
      </Dialog>

      <Button icon="edit" text={'Selecionar estilos'} minimal onClick={onOpen} />
    </>
  );
};
