import { Button } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';
import { SectionTab } from 'polotno/side-panel';
import { IoResizeOutline } from 'react-icons/io5';

const AVAILABLE_SIZES = [{ width: 137, height: 188 }];

export const CustomSizeSection = {
  name: 'sizes',
  Tab: (props: any) => (
    <SectionTab name="Sizes" {...props}>
      <IoResizeOutline style={{ margin: 'auto' }} size="20" />
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: observer(({ store }: { store: any }) => {
    return (
      <div>
        {AVAILABLE_SIZES.map(({ width, height }, i) => (
          <Button
            key={i}
            style={{ width: '100%', marginBottom: '20px' }}
            onClick={() => {
              store.setSize(width, height);
            }}>
            {width}x{height}
          </Button>
        ))}
      </div>
    );
  }),
};
