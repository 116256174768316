import { Button, Dialog, FormGroup, Intent, MenuItem, Switch } from '@blueprintjs/core';
import { Select2 } from '@blueprintjs/select';
import { useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { DialogBody, DialogFooter } from 'src/Components/Dialog';
import { AppToaster } from 'src/Components/Toast';
import { ElementTypeName } from 'src/Services/Json/studioInterface';

export const SelectImageType = ({ store }: { store: any }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const element = store.selectedElements[0];
  const [elementType, setElementType] = useState<ElementTypeName>('DesignElementImageStatic');
  const [isRequired, setRequired] = useState(false);

  useEffect(() => {
    if (!element.custom) return store.selectedElements[0].set({ custom: { type: 'DesignElementImageStatic' } });

    setElementType(element.custom.type);
    setRequired(element.custom.required ?? false);
  }, [element]);

  const renderData = () => {
    store.selectedElements[0].set({
      custom: {
        type: elementType,
        required: isRequired,
      },
    });

    onClose();

    AppToaster.show({
      intent: Intent.SUCCESS,
      message: 'Imagem editada e salva com sucesso.',
    });
  };

  // const isSelected = element.id.startsWith('DesignElementImageUpload') || element.id.startsWith('DesignElementImageStatic');

  return (
    <>
      <Dialog title="Personalizar imagem" isOpen={isOpen} onClose={onClose}>
        <DialogBody>
          <FormGroup label="Selecionar o tipo do elemento">
            <Select2<ElementTypeName>
              filterable={false}
              items={['DesignElementImageUpload', 'DesignElementImageStatic']}
              itemRenderer={(str) => (
                <MenuItem
                  active={str === elementType}
                  key={str}
                  onClick={() => {
                    setElementType(str);
                  }}
                  roleStructure="listoption"
                  label={str}
                  text={str === 'DesignElementImageUpload' ? 'Imagem editável ' : 'Imagem fixa'}
                />
              )}
              noResults={<MenuItem disabled={true} text="No results." roleStructure="listoption" />}
              onItemSelect={() => {}}>
              <Button text={elementType} rightIcon="double-caret-vertical" />
            </Select2>
          </FormGroup>
          <FormGroup>
            <Switch label="Imagem obrigatória para edição" checked={isRequired} onChange={(e: any) => setRequired(e.target.checked)} />
          </FormGroup>
        </DialogBody>
        <DialogFooter label="Salvar" handleClose={renderData} />
      </Dialog>

      <Button icon="edit" text={'Selecionar tipo'} minimal onClick={onOpen} />
    </>
  );
};
