import localforage from 'localforage';
import { unstable_registerShapeModel, unstable_setRemoveBackgroundEnabled } from 'polotno/config';
import { createStore } from 'polotno/model/store';

declare global {
  interface Window {
    store: ReturnType<typeof createStore>;
  }
}

export const setupPolotno = () => {
  unstable_setRemoveBackgroundEnabled(true);

  const store = createStore({ key: 'uv2rSrlf1xUWV3lJPOQC', showCredit: false });
  window.store = store;

  unstable_registerShapeModel({
    type: 'star',
    radius: 100,
    fill: 'black',
    numPoints: 6,
  });

  localforage.getItem('polotno-state', function (err, json) {
    if (json) {
      store.loadJSON(json);
    }
    if (!store.pages.length) {
      store.addPage();
    }
  });

  store.on('change', () => {
    try {
      const json = store.toJSON();
      localforage.setItem('polotno-state', json);
    } catch (e) {
      console.log(e);
    }
  });

  return store;
};
