import { StoreType } from 'polotno/model/store';
import * as svg from 'polotno/utils/svg';

export const getCustomSvgData = async (store: StoreType) => {
  const data: { [key: string]: string } = {};

  await Promise.all(
    store.pages.map(async (v) => {
      await Promise.all(
        v.children.map(async (element) => {
          if (element.type === 'svg') {
            const svgString = await svg.urlToString(element.src);
            const fixedSizesString = svg.fixSize(svgString);
            const newSvgString = svg.replaceColors(fixedSizesString, element.colorsReplace);
            data[element.id] = newSvgString;
          }
        }),
      );
    }),
  );

  return data;
};
