import { Tab, Tabs } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';
import { SectionTab } from 'polotno/side-panel';
import { useState } from 'react';
import { TbTextResize } from 'react-icons/tb';
import { Container } from 'src/Components/Container';
import { CustomTexts } from '../CustomText';

export const TextsSection = {
  name: 'Textos',
  Tab: (props: any) => (
    <SectionTab name="Textos" {...props}>
      <TbTextResize style={{ margin: 'auto' }} size="20" />
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: observer(({ store }: any) => {
    const [tab, setTab] = useState('texts');

    return (
      <>
        <Tabs
          id="TemplatesTab"
          onChange={(e) => {
            setTab(e.toString());
          }}
          selectedTabId={tab}>
          <Tab id="texts" title="Textos" />
          <Tab id="fonts" title="Fonts" />
          <Tab id="colors" title="Colors" panelClassName="ember-panel" />
          <Tabs.Expander />
        </Tabs>
        <Container
          css={{
            marginTop: '10px',
          }}>
          {tab === 'texts' && <CustomTexts store={store} />}
        </Container>
      </>
    );
  }),
};
