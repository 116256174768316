import { Button } from '@blueprintjs/core';

export const OpenFile = ({ store }: { store: any }) => {
  return (
    <label htmlFor="load-project">
      <Button
        icon="folder-open"
        minimal
        onClick={() => {
          (document.querySelector('#load-project') as any)!.click();
        }}>
        Abrir
      </Button>
      <input
        type="file"
        id="load-project"
        accept=".json,.polotno"
        style={{ width: '180px', display: 'none' }}
        onChange={(e) => {
          var input = e.target;

          if (!input.files?.length) {
            return;
          }

          var reader = new FileReader();
          reader.onloadend = function () {
            var text = reader.result;
            let json;
            try {
              json = JSON.parse(text as string);
            } catch (e) {
              alert('Can not load the project.');
            }

            if (json) {
              store.loadJSON(json);
              input.value = '';
            }
          };
          reader.onerror = function () {
            alert('Can not load the project.');
          };
          reader.readAsText(input.files[0]);
        }}
      />
    </label>
  );
};
