import { StoreType } from 'polotno/model/store';
import { SelectImageType, SelectTextStyledType } from './Components';
import { SelectTextPlainType } from './Components/SelectTextPlainType';

export const ToolBarComponents = {
  ActionControls: ({ store }: { store: StoreType }) => {
    const element = store.selectedElements[0];

    if (store.selectedElements.length > 1) return <></>;

    switch (element?.type) {
      case 'image':
        return <SelectImageType store={store} />;
      case 'text':
        if (element.custom?.type === 'DesignElementTextStyled') return <SelectTextStyledType element={element} store={store} />;
        else if (element.custom?.type === 'DesignElementTextPlain') return <SelectTextPlainType element={element} />;

        break;
      default:
        return <></>;
    }

    return <></>;
  },
};
