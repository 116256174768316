import { Intent } from '@blueprintjs/core';
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno';
import { Workspace } from 'polotno/canvas/workspace';
import { setTranslations, unstable_setTextVerticalResizeEnabled } from 'polotno/config';
import { ElementsSection, LayersSection, PhotosSection, SectionTab, SidePanel, SizeSection } from 'polotno/side-panel';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
import { useEffect } from 'react';
import { BsTextareaResize } from 'react-icons/bs';
import { FaRegImages, FaShapes } from 'react-icons/fa';
import { FiLayers } from 'react-icons/fi';
import { AppToaster } from './Components/Toast';
import { TopBar } from './Components/TopBar';
import { QrSection, ToolBarComponents } from './Custom';
import { TextsSection } from './Custom/Sections/TextCustomizationsSection';
import { UploadImages } from './Custom/Sections/UploadImages/UploadImages';
import Translations from './Data/Translations.json';
import { useHeight } from './Hooks';

export type PolotnoTab = typeof ElementsSection;

setTranslations(Translations);
unstable_setTextVerticalResizeEnabled(true);

const App = ({ store }: { store: any }) => {
  const height = useHeight();

  (ElementsSection as any).Tab = (props: any) => (
    <SectionTab name="Elementos" {...props}>
      <FaShapes style={{ margin: 'auto' }} size="20" />
    </SectionTab>
  );

  (PhotosSection as any).Tab = (props: any) => (
    <SectionTab name="Fotos" {...props}>
      <FaRegImages style={{ margin: 'auto' }} size="20" />
    </SectionTab>
  );

  (LayersSection as any).Tab = (props: any) => (
    <SectionTab name="Camadas" {...props}>
      <FiLayers style={{ margin: 'auto' }} size="20" />
    </SectionTab>
  );

  (SizeSection as any).Tab = (props: any) => (
    <SectionTab name="Redimensionar" {...props}>
      <BsTextareaResize style={{ margin: 'auto' }} size="20" />
    </SectionTab>
  );

  const sections: any[] = [TextsSection, PhotosSection, ElementsSection, QrSection, LayersSection, SizeSection, UploadImages];

  useEffect(() => {
    store.on('change', () => {
      if (!store.selectedElements.length) return;

      const [element] = store.selectedElements;

      if (element.rotation > 15) {
        element.set({ rotation: 15 });

        AppToaster.show({
          intent: Intent.DANGER,
          message: 'A rotação de um elemento não pode ser maior que 15.',
        });
      } else if (element.rotation < -15) {
        element.set({ rotation: -15 });

        AppToaster.show({
          intent: Intent.DANGER,
          message: 'A rotação de um elemento não pode ser menor que -15.',
        });
      }
    });
  }, []);

  useEffect(() => {
    const json = store.toJSON();

    json.fonts.forEach((v: any) => {
      store.removeFont(v.fontFamily);
    });

    // personFonts.forEach((v) => {
    //   store.addFont({
    //     fontFamily: v.name,
    //     url: v.asset.source,
    //   });
    // });
  }, []);

  return (
    <div
      style={{
        width: '100vw',
        height: height + 'px',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <TopBar store={store} />
      <div style={{ height: 'calc(100% - 50px)' }}>
        <PolotnoContainer className="polotno-app-container">
          <SidePanelWrap>
            <SidePanel store={store} sections={sections} />
          </SidePanelWrap>
          <WorkspaceWrap>
            <Toolbar store={store} hideTextEffects hideImageEffects hideSvgEffects components={ToolBarComponents} />
            <Workspace store={store} components={{ PageControls: () => null }} />
            <ZoomButtons store={store} />
          </WorkspaceWrap>
        </PolotnoContainer>
      </div>
    </div>
  );
};

export default App;
