import { Button, Classes } from '@blueprintjs/core';
import { Box, HStack } from '@chakra-ui/react';
import { ReactNode } from 'react';

export function DialogFooter(props: {
  handleClose: (e: React.MouseEvent) => void;
  label: ReactNode;
  loading?: boolean;
  children?: ReactNode;
  leftElement?: ReactNode;
}) {
  return (
    <div className={Classes.DIALOG_FOOTER}>
      <HStack>
        <Box flexGrow={1}>{props.leftElement || <></>}</Box>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {props.children || <></>}
          <Button onClick={props.handleClose} loading={props.loading}>
            {props.label}
          </Button>
        </div>
      </HStack>
    </div>
  );
}

export function DialogBody({ children }: { children: ReactNode }) {
  return <div className={Classes.DIALOG_BODY}>{children}</div>;
}
