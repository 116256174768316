import styled from 'polotno/utils/styled';

export const NavbarContainer = styled('div')`
  @media screen and (max-width: 500px) {
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100vw;
  }
`;

export const NavInner = styled('div')`
  @media screen and (max-width: 500px) {
    display: flex;
  }
`;
