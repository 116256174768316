import { ControlGroup, FormGroup, Intent, TagInput, Text } from '@blueprintjs/core';
import { Box, HStack } from '@chakra-ui/react';
import { StoreType } from 'polotno/model/store';
import { ColorPicker } from 'polotno/toolbar/color-picker';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import rgbHex from 'rgb-hex';
import { isValidColor } from 'src/Utils/color';

interface Props {
  colors: string[];
  setColors: Dispatch<SetStateAction<string[]>>;
  store: StoreType;
}

export const CustomColors = ({ colors, setColors, store }: Props) => {
  const [newColor, setNewColor] = useState('');

  // // const addColor = () => {
  // //   if (!currentColor || colors.find((item) => item.hex === currentColor.hex)) return;

  // //   setColors((prev) => [...prev, currentColor]);
  // //   setCurrentColor(undefined);
  // // };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setColors((prev) => [...prev, newColor]);
    }, 500);

    return () => clearTimeout(timeout);
  }, [newColor]);

  return (
    <FormGroup>
      <HStack spacing={3} mb={2}>
        <Box flexGrow={1}>
          <Text>Selecionar cores</Text>
        </Box>
        {/* {colors.filter((v) => v.trim()).length > 0 && (
          <Button small onClick={() => setColors(defaultColors.map((v) => v.hex))}>
            Restaurar padrões
          </Button>
        )} */}
      </HStack>

      <ControlGroup fill={true} vertical={false}>
        <TagInput
          values={colors}
          onChange={(values) => {
            if (values.length > colors.length) {
              const lastValue = values[values.length - 1];

              if (!isValidColor(lastValue as string)) return;
            }

            setColors(values.filter((item) => (item as string).trim()) as string[]);
          }}
          placeholder="Separate values with commas..."
          tagProps={(_, index) => ({
            style: {
              backgroundColor: colors[index],
            },
            intent: Intent.PRIMARY,
            minimal: false,
          })}
          addOnPaste
          addOnBlur
          fill={true}
        />
        <ColorPicker
          value={newColor}
          size={30}
          onChange={(v) => {
            setNewColor(`#${rgbHex(v)}`);
          }}
          store={store}
        />
      </ControlGroup>
    </FormGroup>
  );
};
