import { Button, Spinner } from '@blueprintjs/core';
import { Text } from '@chakra-ui/react';
import { useLocalStorage } from '@postless/core';

import axios from 'axios';
import { observer } from 'mobx-react-lite';
import { ImagesGrid, SectionTab } from 'polotno/side-panel';
import { getImageSize } from 'polotno/utils/image';
import qs from 'qs';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FiUpload } from 'react-icons/fi';
import { Container } from 'src/Components/Container';
import { imagesServicesClient } from 'src/Services/Images/api';

const uploadImage = async (
  input: {
    contentType: string;
    bucketName: string;
  },
  file: File,
) => {
  const fileName = `${file.name.replace(/\s/g, '-')}-${crypto.randomUUID()}`;

  const {
    data: {
      data: { url },
    },
  } = await imagesServicesClient.get<{
    data: {
      url: string;
    };
  }>(
    `/getPresignedUrl?${qs.stringify({
      ...input,
      fileName,
    })}`,
  );

  await axios.put(url, file, {
    headers: {
      'Content-Type': file.type,
      'x-amz-acl': 'public-read',
    },
  });

  const href = `${process.env.REACT_APP_BUCKET_URL}/${fileName}`;

  return { href };
};

export const UploadImages = {
  name: 'Upload Images',
  Tab: (props: any) => (
    <SectionTab name="Imagens" {...props}>
      <FiUpload style={{ margin: 'auto' }} size="20" />
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: observer(({ store }: any) => {
    const [isUploadLoading, setUploadLoading] = useState(false);
    // const [search, setSearch] = useState<string>('');
    const [images, setImages] = useLocalStorage<string[]>('custom-images', []);

    const uploadImages = async (files: File[]) => {
      setUploadLoading(true);
      const newImages: string[] = [];

      for (const file of files) {
        const { href } = await uploadImage(
          {
            bucketName: process.env.REACT_APP_BUCKET_NAME!,
            contentType: file.type,
          },
          file,
        );
        newImages.push(href);
      }

      setImages((data) => [...data, ...newImages]);
      setUploadLoading(false);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop: uploadImages,
      accept: {
        'image/*': ['.png', '.jpg', '.webp', '.svg'],
      },
    });

    return (
      <Container>
        {/* <InputGroup
          leftIcon="search"
          placeholder="Procurar imagem"
          onChange={({ target }) => setSearch(target.value)}
          
          style={{
            marginBottom: '5px',
          }}
        /> */}

        <Button
          icon={isUploadLoading ? <Spinner size={14} /> : 'upload'}
          style={{ width: '100%', marginBottom: '20px' }}
          {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? 'Solte aqui sua imagem' : 'Enviar/arrastar imagem'}
        </Button>

        <ImagesGrid
          isLoading={false}
          itemHeight={100}
          shadowEnabled={false}
          getCredit={(image: string) => (
            <p>
              {image}
              <br />
              <Text
                cursor={'pointer'}
                style={{ color: 'red' }}
                onClick={async (e) => {
                  e.stopPropagation();

                  setImages((prev) => prev.filter((i) => i !== image));
                }}>
                Excluir
              </Text>
            </p>
          )}
          images={images}
          getPreview={(image) => image}
          onSelect={async (image: string, pos: any, element: any) => {
            const url = image;

            let { width, height } = await getImageSize(url);

            const isSVG = url.indexOf('svg+xml') >= 0 || url.indexOf('.svg') >= 0;

            const type = isSVG ? 'svg' : 'image';

            if (element && element.type === 'svg' && !element.locked && type === 'image') {
              element.set({ maskSrc: url });
              return;
            }

            if (element && element.type === 'image' && !element.locked && type === 'image') {
              element.set({ src: url });
              return;
            }

            const x = pos?.x || 0;
            const y = pos?.y || 0;

            const scale = Math.min(store.width / width, store.height / height, 1);
            width = width * scale;
            height = height * scale;

            store.activePage.addElement({
              type,
              src: url,
              x,
              y,
              width,
              height,
            });
          }}
          rowsNumber={2}
          loadMore={false}
        />
      </Container>
    );
  }),
};
