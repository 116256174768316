import { Button, Dialog, Intent } from '@blueprintjs/core';
import { useDisclosure } from '@chakra-ui/react';
import { ElementType, StoreType } from 'polotno/model/store';
import { useEffect, useState } from 'react';
import { DialogBody, DialogFooter } from 'src/Components/Dialog';
import { AppToaster } from 'src/Components/Toast';
import { StyledTextCustomData } from 'src/Services/Json/interface';
import { mapGoogleFontNameToFontFace } from 'src/Services/Json/mappers';
import { SelectMaxCars } from '../SelectMaxChars';
import { CustomColors, CustomFonts } from './Components';

export const SelectTextStyledType = ({ element, store }: { element: ElementType; store: StoreType }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [fonts, setFonts] = useState<string[]>([]);
  const [colors, setColors] = useState<string[]>([]);
  const [maxChars, setMaxChars] = useState(0);

  const renderData = () => {
    const clone = Object.assign({}, element);

    element.set({
      custom: {
        ...clone.custom,
        fonts: fonts.map(mapGoogleFontNameToFontFace),
        colors: colors.filter((item) => item.trim()),
        maxChars,
      } as StyledTextCustomData,
    });

    onClose();

    AppToaster.show({
      intent: Intent.SUCCESS,
      message: 'Alteração salva.',
    });
  };

  // const { defaultColors } = useTextStyled();

  // const defaultFonts = personFonts.filter((item) => item.isDefault);

  // useEffect(() => {
  //   setColors(defaultColors.map((v) => v.hex));

  //   setFonts(defaultFonts);
  // }, []);

  useEffect(() => {
    const data: StyledTextCustomData = element.custom;

    setMaxChars(data.maxChars ?? 0);
    // setFonts(!data.fonts?.length ? defaultFonts : data.fonts);
    // setColors(!data.colors?.length ? defaultColors.map((v) => v.hex) : data.colors);
  }, [element.id]);

  return (
    <>
      <Dialog title="Personalizar Text Styled" isOpen={isOpen} onClose={onClose}>
        <DialogBody>
          <CustomFonts
            {...{
              fonts,
              setFonts,
              store,
            }}
          />
          <CustomColors
            {...{
              colors,
              setColors,
              store,
            }}
          />
          <SelectMaxCars
            {...{
              maxChars,
              setMaxChars,
            }}
          />
        </DialogBody>
        <DialogFooter label="Salvar" handleClose={renderData} />
      </Dialog>

      <Button icon="edit" text={'Selecionar estilos'} minimal onClick={onOpen} />
    </>
  );
};
