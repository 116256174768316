import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import { EventsProvider } from '../Events';

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <ChakraProvider resetCSS>
      <EventsProvider>{children}</EventsProvider>
    </ChakraProvider>
  );
};
