import { StoreType } from 'polotno/model/store';
import { generateId } from './formatStudio';
import { FontFace, StyledColor } from './studioInterface';

export const mapTextStyledColors = (value: string) => {
  const data: StyledColor = {
    __typename: 'DesignColor',
    id: generateId(),
    name: `Color-${value}`,
    value: value,
  };

  return data;
};

export const mapGoogleFontNameToFontFace = (value: string): FontFace => {
  return {
    __typename: 'DesignFontFace',
    id: generateId(),
    name: value,
    urls: {
      ...getFontUrl(value),
      __typename: 'DesignFontFaceUrls',
    },
  };
};

/**
 *
 * @param fontsData Google fonts list string[]
 * @returns
 */
export const getFontUrl = (font: string, fontsData?: string[], jsonFonts?: StoreType['fonts']) => {
  const data = jsonFonts?.find((item) => item.fontFamily === font);

  if (!data) {
    if (fontsData && !fontsData?.includes(font))
      console.warn(`Font load error: Font ${font} aren't available in custom fonts or in Google Fonts.`);
    return {
      css: `https://fonts.googleapis.com/css2?family=${font}`,
    };
  }

  const url = data?.url ?? '';

  return {
    ttf: url,
    woff: url,
    woff2: url,
  };
};
