export const GLOBAL_DEFAULT_COLORS = ['#1C4650', '#D0C4F7', '#60D086', '#754054', '#ED6C3A'];
export const GLOBAL_DEFAULT_FONTS = [
  'Montserrat',
  'Roboto',
  'Poppins',
  'Oswald',
  'Inter',
  'Playfair Display',
  'Quicksand',
  'Caveat',
  'Bebas Neue',
  'Crushed',
  'Pacifico',
  'Permanent Marker',
  'Amatic SC',
  'Sacramento',
  'Damion',
  'Manjari',
];
